<div>
    <div class="flex mb-2">
        <span nz-icon nzType="info-circle" nzTheme="fill" class="pop-message-icon"></span>
        <div class="w-full">
            <h5 class="mb-1">{{ titleText }}</h5>
            <p *ngIf="bodyText" class="whitespace-pre-line">{{ bodyText }}</p>
            <div *ngIf="bodyInputType" [ngSwitch]="bodyInputType" [ngClass]="bodyInputClass">
                <ng-container *ngSwitchCase="'textarea'">
                    <textarea [placeholder]="bodyInputPlaceholder" nzSize="small" nz-input [(ngModel)]="input" maxlength="500"></textarea>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <app-ui-input [nzPlaceHolder]="bodyInputPlaceholder" [(ngModel)]="input" nzMaxLength="500"></app-ui-input>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="confirmBtn || cancelBtn" class="flex gap-2 justify-end items-center">
        <button *ngIf="cancelBtn"
                (click)="emitData({cancel: true})"
                nz-button
                class="btn-cancel"
                nzSize="default"
        >{{ cancelBtnHtml }}</button>
        <button *ngIf="confirmBtn"
                (click)="emitData({confirm: true, inputData: input})"
                nz-button
                nzType="primary"
                nzSize="default"
        >{{ confirmBtnHtml }}</button>
    </div>
</div>
